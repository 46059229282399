<nav class="navbar navbar-expand-lg navbar-primary bg-light">
  <div class="container-fluid">
    <a class="navbar-brand" href="#">
      <img
        src="assets/Bosch_symbol_logo_black_red.svg"
        alt="Bosch Logo"
        width="200"
        height="80" />
      {{ title }}
    </a>
    <button
      class="navbar-toggler"
      type="button"
      data-bs-toggle="collapse"
      data-bs-target="#navbarScroll"
      aria-controls="navbarScroll"
      aria-expanded="false"
      aria-label="Toggle navigation">
      <span class="navbar-toggler-icon"></span>
    </button>
    <div class="collapse navbar-collapse" id="navbarScroll">
      <ul
        class="navbar-nav me-auto my-2 my-lg-0 navbar-nav-scroll"
        style="--bs-scroll-height: 100px">
        <!--        <li class="nav-item">-->
        <!--          <a class="nav-link" [routerLink]="['profile']">Profile</a>-->
        <!--        </li>-->
        <!--        <li class="nav-item">-->
        <!--          <a class="nav-link" [routerLink]="['webapi']">Webapi</a>-->
        <!--        </li>-->
      </ul>
      <div class="d-flex">
        @if (loginDisplay()) {
          <div class="my-auto me-3" data-cy="account-name">
            {{ displayName() }}
          </div>
          <button id="loginBtn" class="rb-btn" (click)="logout()">
            Logout
          </button>
        } @else {
          <button id="loginBtn" class="rb-btn" (click)="login()">Login</button>
        }
      </div>
    </div>
    <app-toasts aria-live="polite" aria-atomic="true"></app-toasts>
  </div>
</nav>

<router-outlet />
