import { Component, OnInit, Inject } from '@angular/core'
import { HttpClient } from '@angular/common/http'
import {
  MsalService,
  MsalBroadcastService,
  MSAL_GUARD_CONFIG,
  MsalGuardConfiguration,
} from '@azure/msal-angular'
import { InteractionStatus, RedirectRequest } from '@azure/msal-browser'
import { Subject } from 'rxjs'
import { filter, takeUntil } from 'rxjs/operators'
import { APP_CONFIG } from './app.config'
import { AppConfigModel } from '../models/app-config.model'

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrl: './app.component.scss',
})
export class AppComponent {
  title = 'User Management'

  loginDisplay = false
  private readonly _destroying$ = new Subject<void>()

  constructor(
    @Inject(MSAL_GUARD_CONFIG) private msalGuardConfig: MsalGuardConfiguration,
    @Inject(APP_CONFIG) private readonly config: AppConfigModel,
    private broadcastService: MsalBroadcastService,
    private authService: MsalService,
    private readonly http: HttpClient,
  ) {}

  ngOnInit() {
    this.broadcastService.inProgress$
      .pipe(
        filter(
          (status: InteractionStatus) => status === InteractionStatus.None,
        ),
        takeUntil(this._destroying$),
      )
      .subscribe(() => {
        this.setLoginDisplay()
      })
  }

  login() {
    if (this.msalGuardConfig.authRequest) {
      this.authService.loginRedirect({
        ...this.msalGuardConfig.authRequest,
      } as RedirectRequest)
    } else {
      this.authService.loginRedirect()
    }
  }

  logout() {
    this.authService.logoutRedirect({
      postLogoutRedirectUri: this.config.frontend_url,
    })
  }

  setLoginDisplay() {
    const accounts = this.authService.instance.getAllAccounts()

    this.loginDisplay = this.authService.instance.getAllAccounts().length > 0
  }

  ngOnDestroy(): void {
    this._destroying$.next(undefined)
    this._destroying$.complete()
  }
  /* Changes end here. */
}
