import {
  Component,
  EventEmitter,
  Input,
  OnInit,
  output,
  Output,
} from '@angular/core'

@Component({
  selector: 'app-dropdown-multi',
  template: `
    <div class="dropdown" style="min-width: 100px;">
      <button
        class="btn btn-secondary dropdown-toggle"
        type="button"
        data-bs-toggle="dropdown"
        aria-expanded="false">
        <span class="dropdown-text">
          @if (selected.length > 0 && areSelectedItemsIncludetInItems()) {
            {{ selected.join(' | ') }}
          } @else {
            -
          }
        </span>
      </button>
      <ul class="dropdown-menu">
        @for (item of items; track item) {
          <li>
            <a
              [class.active]="selected.includes(item)"
              class="dropdown-item"
              (click)="onItemSelect(item)"
              href="#"
              >{{ item }}</a
            >
          </li>
        }
      </ul>
    </div>
  `,
  styleUrl: './dropdown-multi.component.scss',
})
export class DropdownMultiComponent implements OnInit {
  @Input() public items: string[] = ['test', 'test2', 'test3']
  @Input() public selected: string[] = []
  selectionChange = output<string[]>()

  ngOnInit(): void {
    if (this.items === undefined || this.items.length === 0) {
      this.selected = []
    } else {
      if (!Array.isArray(this.selected)) {
        this.selected = [this.selected] // Convert single string to an array
      }
      this.selected = this.selected.filter((item) => this.items.includes(item))
    }
  }

  onItemSelect(item: string) {
    if (this.selected.includes(item)) {
      this.selected = this.selected.filter((i) => i !== item)
    } else {
      this.selected.push(item)
      this.selected.sort()
    }
    this.selectionChange.emit(this.selected)
  }

  areSelectedItemsIncludetInItems(): boolean {
    return this.selected.filter((item) => this.items.includes(item)).length > 0
  }
}
