import {
  Component,
  computed,
  DestroyRef,
  inject,
  OnDestroy,
  OnInit,
} from '@angular/core'
import { MsalBroadcastService, MsalService } from '@azure/msal-angular'
import { toSignal } from '@angular/core/rxjs-interop'
import {
  AccountInfo,
  AuthenticationResult,
  EventMessage,
  EventType,
  IdTokenClaims,
  InteractionStatus,
} from '@azure/msal-browser'
import { filter, takeUntil } from 'rxjs/operators'
import { UserManagementService } from '../../service/user-management.service'
import { AuthService } from '../../service/auth.service'
import { AUTH_SERVICE } from '../app.config'
import { MemberModel } from '../../models/member-model'
import { Subject, first, tap } from 'rxjs'
import { HttpErrorResponse } from '@angular/common/http'
import { GroupModel } from '../../models/group-model'

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrl: './home.component.scss',
  standalone: false,
})
export class HomeComponent implements OnInit, OnDestroy {
  _destroying$ = new Subject<void>()
  public isLoggedIn = computed(() => this.authService.loginDisplay())
  public getUserMessage = 'Loading...'
  public isAuthorized = false
  private destroyRef = inject(DestroyRef)

  constructor(
    private authService: AuthService,
    public readonly userManagementService: UserManagementService,
  ) {}

  public async ngOnInit(): Promise<void> {
    const subscription = this.authService.activeAccountChanges
      .pipe()
      .subscribe((account: AccountInfo) => {
        this.isAuthorized = (account.idTokenClaims as any).extension_isAdmin
        this.getUser(account)
      })
    this.destroyRef.onDestroy(() => {
      subscription.unsubscribe()
    })
    await this.authService.InitializeMsal()
  }

  ngOnDestroy(): void {
    this._destroying$.next(undefined)
    this._destroying$.complete()
  }

  public async getUser(account: AccountInfo) {
    const response = this.userManagementService.getUser().subscribe({
      error: (err: HttpErrorResponse) => {
        this.getUserMessage = `Unable to load users!`
      },
    })
  }
}
