import {
  Component,
  computed,
  HostListener,
  Input,
  Signal,
  AfterContentChecked,
} from '@angular/core'
import { GroupModel, MemberModel } from '../../models/member-model'
import { UserManagementService } from '../../service/user-management.service'
import { ToastService } from '../../service/app-toast.service'
import { HttpErrorResponse, HttpResponse } from '@angular/common/http'
import { concat, concatMap, map, Observable, throwError } from 'rxjs'
import { AuthService } from '../../service/auth.service'

@Component({
  selector: 'app-group-management',
  templateUrl: './group-management.component.html',
  styleUrl: './group-management.component.scss',
  standalone: false,
})
export class GroupManagementComponent {
  @Input() public groupModel!: GroupModel
  public mailAddress: string = ''
  public isRKeyPressed: boolean = false

  activeUserId: Signal<string> = computed(() => this.authService.activeUserId())
  private scrollPosition = 0

  @HostListener('window:keydown.r', ['$event']) onKeyDown(
    event: KeyboardEvent,
  ) {
    // Beispiel: Reagiere auf spezifische Tasten
    if (!this.isRKeyPressed) {
      this.isRKeyPressed = true
    }
  }

  @HostListener('window:keyup.r', ['$event']) onKeyUp(event: KeyboardEvent) {
    // Beispiel: Reagiere auf spezifische Tasten
    this.isRKeyPressed = false
  }

  constructor(
    private readonly userManagementService: UserManagementService,
    private readonly authService: AuthService,
    private readonly toasterService: ToastService,
  ) {}

  public async onRoleSelectionchange(
    selectedRole: string,
    member: MemberModel,
  ): Promise<void> {
    console.log(`${member.displayName} new role: ${selectedRole}`)
    const oldRole = member.role
    member.role = selectedRole

    await this.updateUserWorkflow(member)
  }

  public async onScopeSelectionchange(
    selectedScopes: string[],
    member: MemberModel,
  ) {
    console.log(`${member.displayName} new role: ${selectedScopes}`)
    const oldScopes = member.backendScopes
    member.backendScopes = selectedScopes

    await this.updateUserWorkflow(member)
  }

  private async updateUserWorkflow(member: MemberModel) {
    const updateUser$ = this.updateUser(member)
    const getUser$ = this.userManagementService.getUser()

    concat(updateUser$, getUser$).subscribe({
      next: (response) => {},
      complete: () => {
        this.toasterService.show({
          type: 'success',
          message: `user updated successfully`,
        })
      },
      error: (errorResp) => {
        this.toasterService.show({
          type: 'error',
          message: `${errorResp.status}: ${errorResp.statusText}`,
        })
      },
    })
  }

  private updateUser(member: MemberModel): Observable<string> {
    const groupInfo = {
      displayName: this.groupModel.group?.displayName ?? '',
      id: this.groupModel.group?.id ?? '',
    }
    const scopes = member.backendScopes.filter((scope) =>
      this.groupModel.backendscopes.includes(scope),
    )
    member.backendScopes = scopes
    const updateUser$ = this.userManagementService.updateUserInGroup(
      member,
      groupInfo,
    )

    return updateUser$
  }

  public async inviteUserWorkflow(
    mail: string,
    groupName: string,
  ): Promise<void> {
    mail = mail.trim()
    if (mail == '') {
      return
    }
    const inviteUser$ = this.userManagementService.inviteUserInGroup(
      mail,
      groupName,
    )
    const getUser$ = this.userManagementService.getUser()

    inviteUser$
      .pipe(
        concatMap(() => getUser$),
        concatMap(() => {
          const userRole = this.groupModel.roles.find((x) => x.includes('user'))
          const member = this.userManagementService.groupModel
            .find((group) => group.group?.id === this.groupModel.group?.id)
            ?.members?.find((member) => member.mail === mail)

          if (member === undefined || userRole === undefined) {
            return throwError(() => new Error('Member or userRole not found.'))
          }

          member.role = userRole
          return this.updateUser(member) // Rückgabe von `updateUser$`
        }),
        concatMap(() => getUser$),
      )
      .subscribe({
        complete: () => {
          this.mailAddress = ''

          this.toasterService.show({
            type: 'success',
            message: `User invited successfully`,
          })
        },
        error: (errorResp: HttpErrorResponse | Error) => {
          if (errorResp instanceof HttpErrorResponse) {
            this.toasterService.show({
              type: 'error',
              message: `${errorResp.status}: ${errorResp.statusText}`,
            })
          } else {
            this.toasterService.show({
              type: 'error',
              message: `Error: ${errorResp.message}`,
            })
          }
        },
      })
  }

  public async deleteUserWorkflow(member: MemberModel): Promise<void> {
    const deleteUser$ = this.userManagementService.removeUserFromGroup(
      member.id,
      this.groupModel.group?.id ?? '',
      this.groupModel.group?.displayName ?? '',
    )
    const getUser$ = this.userManagementService.getUser()

    concat(deleteUser$, getUser$).subscribe({
      next: (response) => {},
      complete: () => {
        this.mailAddress = ''
        this.toasterService.show({
          type: 'success',
          message: `user deleted successfully`,
        })
      },
      error: (errorResp: HttpErrorResponse | Error) => {
        if (errorResp instanceof HttpErrorResponse) {
          if (errorResp.status === 401) {
            this.toasterService.show({
              type: 'error',
              message: `Error: You are not allowed to delete this user.`,
            })
          } else {
            this.toasterService.show({
              type: 'error',
              message: `${errorResp.status}: ${errorResp.statusText}`,
            })
          }
        } else {
          this.toasterService.show({
            type: 'error',
            message: `Error: ${errorResp.message}`,
          })
        }
      },
    })
  }
}
