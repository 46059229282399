import { NgModule } from '@angular/core'
import { BrowserModule } from '@angular/platform-browser'

import { AppRoutingModule } from './app-routing.module'
import { AppComponent } from './app.component'

import {
  MsalGuard,
  MsalInterceptor,
  MsalModule,
  MsalRedirectComponent,
} from '@azure/msal-angular'
import { InteractionType, PublicClientApplication } from '@azure/msal-browser'

import { msalConfig } from './auth-config'

import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http'
import { HomeComponent } from './home/home.component'
import { AUTH_SERVICE } from './app.config'
import { AuthService } from '../service/auth.service'
import { DropdownSingleComponent } from './controlls/dropdown-single/dropdown-single.component'
import { DropdownMultiComponent } from './controlls/dropdown-multi/dropdown-multi.component'
import { GroupManagementComponent } from './group-management/group-management.component'
import { FormsModule } from '@angular/forms'
import { ToastsContainer } from './controlls/toasts-container/toasts-container.component'

@NgModule({
  declarations: [
    AppComponent,
    HomeComponent,
    DropdownSingleComponent,
    DropdownMultiComponent,
    GroupManagementComponent,
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    // Import the HTTP client.
    HttpClientModule,
    FormsModule,
    // Initiate the MSAL library with the MSAL configuration object
    MsalModule.forRoot(
      new PublicClientApplication(msalConfig),
      {
        // The routing guard configuration.
        interactionType: InteractionType.Redirect,
        authRequest: {
          scopes: [],
        },
      },
      {
        // MSAL interceptor configuration.
        // The protected resource mapping maps your web API with the corresponding app scopes. If your code needs to call another web API, add the URI mapping here.
        interactionType: InteractionType.Redirect,
        protectedResourceMap: new Map([['http://localhost:4200', []]]),
      },
    ),
    ToastsContainer,
  ],
  providers: [
    /* Changes start here. */
    {
      provide: HTTP_INTERCEPTORS,
      useClass: MsalInterceptor,
      multi: true,
    },
    {
      provide: AUTH_SERVICE,
      useClass: AuthService,
      multi: false,
    },
    MsalGuard,
    /* Changes end here. */
  ],
  bootstrap: [AppComponent, MsalRedirectComponent],
})
export class AppModule {}
