<div class="dropdown">
    <button class="btn btn-secondary dropdown-toggle" type="button" data-bs-toggle="dropdown" aria-expanded="false">
        {{ selected }}
    </button>
    <ul class="dropdown-menu">
        @for (item of items; track item) {
        <li>
            <a [class.active]="item === selected" class="dropdown-item" (click)="onItemSelect(item)" >
                {{ item }}
            </a>
        </li>
        }
    </ul>
</div>