import { Inject, Injectable } from '@angular/core'
import {
  HttpClient,
  HttpErrorResponse,
  HttpHeaders,
  HttpParams,
  HttpResponse,
} from '@angular/common/http'
import { GroupModel, MemberModel } from '../models/member-model'
import {
  catchError,
  firstValueFrom,
  lastValueFrom,
  Observable,
  throwError,
} from 'rxjs'
import { APP_CONFIG } from '../app/app.config'
import { AppConfigModel } from '../models/app-config.model'

@Injectable({
  providedIn: 'root',
})
export class UserManagementService {
  public groupModel: GroupModel[] = []

  private token: string = ''

  constructor(
    private readonly http: HttpClient,
    @Inject(APP_CONFIG) private readonly config: AppConfigModel,
  ) {
    this.config.backend_url
  }

  public setToken(token: string): void {
    this.token = token
  }

  public async getUser(): Promise<HttpResponse<GroupModel[]>> {
    const httpOptions = {
      headers: new HttpHeaders({
        Authorization: `Bearer ${this.token}`,
      }),
      observe: 'response' as const,
    }

    const observable$ = this.http
      .get<
        GroupModel[]
      >(`${this.config.backend_url}/api/identity/users`, httpOptions)
      .pipe(
        catchError((error: HttpErrorResponse) => {
          // Capture the error and re-throw with status and message
          return throwError(() => error)
        }),
      )

    try {
      // Await the Observable and handle success
      const result = await lastValueFrom(observable$)
      if (result.body === null) {
        // If body is null, throw a custom error
        throw new HttpErrorResponse({
          error: 'Empty response body',
          status: 500,
          statusText: 'Internal Server Error',
          url: `${this.config.backend_url}/api/identity/users`,
        })
      }
      this.groupModel = result.body as GroupModel[]
      return result
    } catch (error: any) {
      return error // Return or handle the error object as needed
    }
  }

  public async inviteUserInGroup(
    mail: string,
    groupName: string,
  ): Promise<HttpResponse<string>> {
    const httpOptions = {
      headers: new HttpHeaders({
        Authorization: `Bearer ${this.token}`,
      }),
    }
    const requestBody = {
      mail: mail,
    }
    const inviteUser$ = this.http
      .put<
        HttpResponse<string>
      >(`${this.config.backend_url}/api/groups/${groupName}/users`, requestBody, httpOptions)
      .pipe(
        catchError((error: HttpErrorResponse) => {
          // Capture the error and re-throw with status and message
          return throwError(() => error)
        }),
      )
    // invateUser$.subscribe({
    //   next: (response) => {
    //     console.log('Status code:', response.status)
    //     console.log('Response body:', response.body)
    //   },
    //   error: (error) => {
    //     console.log('Error status:', error.status)
    //     console.log('Error message:', error.message)
    //   },
    // })

    try {
      // Await the Observable and handle success
      const result = await lastValueFrom(inviteUser$)
      return result
    } catch (error: any) {
      return error // Return or handle the error object as needed
    }
  }

  public async updateUserInGroup(
    memberModel: MemberModel,
    groupInfo: { displayName: string; id: string },
  ): Promise<HttpResponse<string>> {
    const httpOptions = {
      headers: new HttpHeaders({
        Authorization: `Bearer ${this.token}`,
      }),
    }
    const requestBody = {
      id: memberModel.id,
      role: memberModel.role,
      backendScopes: memberModel.backendScopes,
      group: groupInfo,
    }
    const updateUser$ = this.http
      .put<
        HttpResponse<string>
      >(`${this.config.backend_url}/api/users/${memberModel.id}`, requestBody, httpOptions)
      .pipe(
        catchError((error: HttpErrorResponse) => {
          // Capture the error and re-throw with status and message
          return throwError(() => error)
        }),
      )

    try {
      // Await the Observable and handle success
      const result = await lastValueFrom(updateUser$)
      return result
    } catch (error: any) {
      return error // Return or handle the error object as needed
    }
  }

  public async removeUserFromGroup(
    userId: string,
    groupId: string,
    groupDisplayName: string,
  ): Promise<HttpResponse<string>> {
    const httpOptions = {
      headers: new HttpHeaders({
        Authorization: `Bearer ${this.token}`,
      }),
      params: new HttpParams().set('groupid', groupId), // Params hinzugefügt
    }
    const removeUser$ = this.http
      .delete<
        HttpResponse<string>
      >(`${this.config.backend_url}/api/groups/${groupDisplayName}/users/${userId}`, httpOptions)
      .pipe(
        catchError((error: HttpErrorResponse) => {
          // Capture the error and re-throw with status and message
          return throwError(() => error)
        }),
      )

    try {
      // Await the Observable and handle success
      const result = await lastValueFrom(removeUser$)
      return result
    } catch (error: any) {
      return error // Return or handle the error object as needed
    }
  }
}
