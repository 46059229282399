export function attachPopoverToDocument(el: HTMLElement) {
  const isModalOpen = !!document.querySelector('body.rb-modal-open')
  if (isModalOpen) {
    const backdrop = document.querySelector('.rb-modal-backdrop')
    if (backdrop) {
      backdrop.appendChild(el)
    }
  } else {
    document.body.appendChild(el)
  }
}
