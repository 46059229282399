<div class="container-fluid">
  @if (isLoggedIn()) {
    <!--        <p>-->
    <!--          Request your profile information by clicking Profile above.-->
    <!--          {{ token }}-->
    <!--        </p>-->

        <div class="mt-4" style="width: min(90%,1250px); margin: auto" id="contentArea">
            @if (this.isAuthorized && this.userManagementService.groupModel.length > 0) {
                @for (groupModel of this.userManagementService.groupModel;
                    track groupModel.group?.id) {
                    <app-group-management [groupModel]="groupModel" data-cy="user-table"/>
                    <br class="lh-lg"/>
                    <br class="lh-lg"/>
                }
            } @else if (this.isAuthorized) {
                <p>{{ getUserMessage }}</p>
            } @else {
                <p>User is not authorized, please contact the group administrator.</p>
            }
        </div>
    } @else {
        <div class="mx-auto" style="width: min(1000px, 75%)">
            <p class="my-4">Please sign-in to see group information.</p>
            <img src="assets/digital-collaboration.svg"/>
        </div>
    }
</div>
