import { platformBrowserDynamic } from '@angular/platform-browser-dynamic'

import { AppModule } from './app/app.module'
import { APP_CONFIG, AUTH_SERVICE } from './app/app.config'

fetch('/config.json')
  .then(async (response) => await response.json())
  .then(async (config): Promise<void> => {
    platformBrowserDynamic([{ provide: APP_CONFIG, useValue: config }])
      .bootstrapModule(AppModule, { ngZoneEventCoalescing: true })
      .catch((err) => console.error(err))
  })
