<div class="dropdown" style="min-width: 100px;">
    <button class="btn btn-secondary dropdown-toggle" type="button" data-bs-toggle="dropdown" aria-expanded="false">
        <span class="dropdown-text">
            @if (selected.length > 0 && areSelectedItemsIncludetInItems()) {
            {{ selected.join(' | ') }}
            } @else {
            -
            }
        </span>
    </button>
    <ul class="dropdown-menu">
        @for (item of items; track item) {
        <li>
            <a [class.active]="selected.includes(item)" class="dropdown-item" (click)="onItemSelect(item)">{{
                item }}</a>
        </li>
        }
    </ul>
</div>