import { Inject, Injectable, OnDestroy, signal } from '@angular/core'
import {
  MSAL_GUARD_CONFIG,
  MsalBroadcastService,
  MsalGuardConfiguration,
  MsalService,
} from '@azure/msal-angular'
import {
  AccountInfo,
  AuthenticationResult,
  EventMessage,
  EventType,
  IdTokenClaims,
  InteractionStatus,
  RedirectRequest,
} from '@azure/msal-browser'
import { Permissions } from '../app/enums/permissions.enum'
import { Subject, filter, takeUntil } from 'rxjs'
import { APP_CONFIG } from '../app/app.config'
import { AppConfigModel } from '../models/app-config.model'

type IdTokenClaimsWithPolicyId = IdTokenClaims & {
  acr?: string
  tfp?: string
  emails?: string[]
}

@Injectable({
  providedIn: 'root',
})
export class AuthService implements OnDestroy {
  _destroying$ = new Subject<void>()
  //loginDisplay = false
  loginDisplay = signal(false)
  displayName = signal('')
  activeUserId = signal('')

  public activeAccountChanges = new Subject<AccountInfo>()
  private activeAccountId = ''

  constructor(
    private readonly msalService: MsalService,
    private readonly msalBroadcastService: MsalBroadcastService,
    @Inject(MSAL_GUARD_CONFIG) private msalGuardConfig: MsalGuardConfiguration,
    @Inject(APP_CONFIG) private readonly config: AppConfigModel,
  ) {
    this.msalService.instance.enableAccountStorageEvents()
    this.msalBroadcastService.msalSubject$
      .pipe(
        filter(
          (msg: EventMessage) =>
            msg.eventType === EventType.LOGIN_SUCCESS ||
            msg.eventType === EventType.ACQUIRE_TOKEN_SUCCESS ||
            msg.eventType === EventType.SSO_SILENT_SUCCESS,
        ),
        takeUntil(this._destroying$),
      )
      .subscribe((result: EventMessage) => {
        let payload = result.payload as AuthenticationResult
        let idtoken = payload.idTokenClaims as IdTokenClaimsWithPolicyId

        this.updateAccountInfo(payload.account)

        if (
          idtoken.acr === this.config.aadb2c_policy ||
          idtoken.tfp === this.config.aadb2c_policy
        ) {
          //console.log('execute msalBroadcastService.msalSubject$')

          this.msalService.instance.setActiveAccount(payload.account)
          if (
            payload.account &&
            payload.account.localAccountId !== this.activeAccountId
          ) {
            let tempAcc = payload.account.localAccountId
            this.activeAccountId = tempAcc
            this.setLoginDisplay()
            this.activeAccountChanges.next(payload.account)
          }
        }

        return result
      })

    this.msalBroadcastService.inProgress$
      .pipe(
        filter(
          (status: InteractionStatus) => status === InteractionStatus.None,
        ),
        takeUntil(this._destroying$),
      )
      .subscribe(() => {
        // console.log('execute msalBroadcastService.inProgress$')
        this.setLoginDisplay()
        this.checkAndSetActiveAccount()
      })
  }

  ngOnDestroy(): void {
    this._destroying$.next(undefined)
    this._destroying$.complete()
  }

  private setLoginDisplay() {
    this.loginDisplay.set(this.msalService.instance.getAllAccounts().length > 0)
  }

  private checkAndSetActiveAccount() {
    /**
     * If no active account set but there are accounts signed in, sets first account to active account
     * To use active account set here, subscribe to inProgress$ first in your component
     * Note: Basic usage demonstrated. Your app may require more complicated account selection logic
     */
    let activeAccount = this.msalService.instance.getActiveAccount()

    if (
      !activeAccount &&
      this.msalService.instance.getAllAccounts().length > 0
    ) {
      let accounts = this.msalService.instance.getAllAccounts()
      this.msalService.instance.setActiveAccount(accounts[0])
      this.activeAccountChanges.next(accounts[0])
    }
  }

  private updateAccountInfo(accountInfo: AccountInfo) {
    const tokenClaims = accountInfo.idTokenClaims
    if (tokenClaims?.emails) {
      this.displayName.set(tokenClaims?.emails[0])
    }
    this.activeUserId.set(tokenClaims?.oid ?? '')
  }

  login() {
    if (this.msalGuardConfig.authRequest) {
      this.msalService.loginRedirect({
        ...this.msalGuardConfig.authRequest,
      } as RedirectRequest)
    } else {
      this.msalService.loginRedirect()
    }
  }

  public logout(): void {
    this.msalService.logoutRedirect({
      postLogoutRedirectUri: this.config.frontend_url,
    })
  }

  public getAccountInfo(): AccountInfo | null {
    return this.msalService.instance.getActiveAccount()
  }

  public getUserClaims(): {
    userPermissions: Permissions[]
    userBackendScopes: string[]
  } {
    let userPermissions: Permissions[] = []
    let userBackendScopes: string[] = []

    const env =
      this.config.environment.charAt(0).toUpperCase() +
      this.config.environment.slice(1)

    const tokenPermissions = this.msalService.instance.getActiveAccount()
      ?.idTokenClaims?.[`extension_${env}_Permissions`] as string

    const tokenDataFilters = this.msalService.instance.getActiveAccount()
      ?.idTokenClaims?.[`extension_${env}_DataFilters`] as string

    const tokenBackendScopes = this.msalService.instance.getActiveAccount()
      ?.idTokenClaims?.[`extension_${env}_BackendScopes`] as string

    // has aad idp
    if (
      this.msalService.instance.getActiveAccount()?.idTokenClaims?.idp ===
      this.config.bosch_aad_idp_id
    ) {
      // has aad idp and no permissions
      if (tokenPermissions == '' || !tokenPermissions) {
        userPermissions = Object.values(Permissions).filter(
          (permission) => !permission.includes('.w'),
        ) as Permissions[]

        // set default data filter for BoschInternal
        //userDataFilters.push(WarningDatasources.Rcs)
      }
      // has aad idp and permissions
      else {
        tokenPermissions
          .split(',')
          .map((permission) =>
            Object.values(Permissions).includes(
              permission.trim() as Permissions,
            )
              ? userPermissions.push(permission.trim() as Permissions)
              : '',
          )
      }
    }
    // has no aad idp and no permissions
    else if (tokenPermissions == '' || !tokenPermissions) {
      userPermissions.push(
        Permissions.Apps_Rc_Access,
        Permissions.RoadSegments_Rc_Read,
      )
    }

    // has no aad idp and permissions
    else {
      tokenPermissions
        .split(',')
        .map((permission) =>
          Object.values(Permissions).includes(permission.trim() as Permissions)
            ? userPermissions.push(permission.trim() as Permissions)
            : '',
        )
    }

    tokenDataFilters
      ? []
      : //tokenDataFilters
        // .split(',')
        // .map((dataFilter: any) => userDataFilters.push(dataFilter.trim()))
        []

    tokenBackendScopes
      ? tokenBackendScopes
          .split(',')
          .map((backendScope: any) =>
            userBackendScopes.push(backendScope.trim()),
          )
      : []
    return { userPermissions, userBackendScopes }
  }

  public hasPermission(
    permissions: Permissions[],
    match: 'all' | 'any' = 'any',
  ): boolean {
    const userPermissions = this.getUserClaims().userPermissions
    if (match === 'all') {
      return permissions.every((permission) =>
        userPermissions.includes(permission),
      )
    } else {
      return permissions.some((permission) =>
        userPermissions.includes(permission),
      )
    }
  }

  public async InitializeMsal() {
    try {
      await this.msalService.instance.initialize()

      const accounts = this.msalService.instance.getAllAccounts()
      if (accounts.length > 0) {
        const accessTokenRequest = {
          scopes: [this.config.scope],
          account: accounts[0],
        }
        await this.msalService.instance.acquireTokenSilent(accessTokenRequest)
      }
    } catch (e) {
      // if it's not possible to acquire a token we log the user out.
      this.logout()
    }
  }
}
