import { Pipe, PipeTransform } from '@angular/core'

@Pipe({
    name: 'replace',
    standalone: false
})
export class ReplacePipe implements PipeTransform {
  transform(value: string, searchValue: string, replaceValue: string): string {
    if (!value) return value // Return the original value if it's null or undefined

    // Replace all occurrences of `searchValue` with `replaceValue`
    return value.replace(new RegExp(searchValue, 'g'), replaceValue)
  }
}
