import { Component, Input, OnInit } from '@angular/core'
import { GroupModel, MemberModel } from '../../models/member-model'
import { UserManagementService } from '../../service/user-management.service'
import { ToastService } from '../../service/app-toast.service'
import { group } from '@angular/animations'
import { HttpErrorResponse, HttpResponse } from '@angular/common/http'

@Component({
  selector: 'app-group-management',
  template: `
    <div class="row">
      <h3 class="col">
        {{ groupModel.group?.displayName | titlecase }}
      </h3>
      <div class="col d-flex px-0">
        <div class="input-group flex-grow-1">
          <span class="input-group-text" id="basic-addon1">
            <i class="bi bi-person-fill-add h5 m-auto"></i>
          </span>
          <input
            type="text"
            class="form-control"
            placeholder="Mail address"
            aria-label="Username"
            aria-describedby="basic-addon1"
            [(ngModel)]="mailAddress" />
        </div>
        <button
          class="btn btn-outline btn-outline-success ms-2"
          (click)="
            inviteUserWorkflow(mailAddress, groupModel.group?.displayName ?? '')
          ">
          <i class="bi bi-send-fill h5 "></i>
        </button>
      </div>
    </div>
    <div class="row">
      <table class="table table-striped">
        <thead>
          <tr>
            <th scope="col">Mail</th>
            <th scope="col">Name</th>
            <th scope="col">Role</th>
            <th scope="col">Scope</th>
            <th scope="col"></th>
          </tr>
        </thead>
        <tbody>
          @for (member of groupModel.members; track member.id) {
            <tr>
              <td class="col-sm-3">{{ member.mail }}</td>
              <td class="col-sm-2">
                {{ member.givenName }} {{ member.surname }}
              </td>
              <td class="col-sm-2">
                <app-dropdown-single
                  [selected]="member.role"
                  [items]="groupModel.roles"
                  (selectionChange)="onRoleSelectionchange($event, member)" />
              </td>
              <td class="col-sm-3">
                <app-dropdown-multi
                  [selected]="member.backendScopes"
                  [items]="groupModel.backendscopes"
                  (selectionChange)="onScopeSelectionchange($event, member)" />
              </td>
              <td class="col-sm-1">
                <div class="d-flex justify-content-end">
                  <!--                  <button-->
                  <!--                    class="btn btn-outline btn-primary update-button"-->
                  <!--                    (click)="-->
                  <!--                      inviteUserToGroup(-->
                  <!--                        mailAddress,-->
                  <!--                        groupModel.group?.displayName ?? ''-->
                  <!--                      )-->
                  <!--                    ">-->
                  <!--                    <i class="bi bi-floppy2"></i>-->
                  <!--                  </button>-->
                  <button
                    class="btn btn-outline btn-outline-danger ms-2 update-button"
                    (click)="deleteUserWorkflow(member)">
                    <i class="bi bi-trash"></i>
                  </button>
                </div>
              </td>
            </tr>
          }
        </tbody>
      </table>
    </div>
  `,
  styleUrl: './group-management.component.scss',
})
export class GroupManagementComponent {
  @Input() public groupModel!: GroupModel
  public mailAddress: string = ''

  constructor(
    private readonly userManagementService: UserManagementService,
    private readonly toasterService: ToastService,
  ) {}

  public async onRoleSelectionchange(
    selectedRole: string,
    member: MemberModel,
  ): Promise<void> {
    console.log(`${member.displayName} new role: ${selectedRole}`)
    const oldRole = member.role
    member.role = selectedRole

    await this.updateUserWorkflow(member)
  }

  public async onScopeSelectionchange(
    selectedScopes: string[],
    member: MemberModel,
  ) {
    console.log(`${member.displayName} new role: ${selectedScopes}`)
    const oldScopes = member.backendScopes
    member.backendScopes = selectedScopes

    await this.updateUserWorkflow(member)
  }

  private async updateUserWorkflow(member: MemberModel) {
    const groupInfo = {
      displayName: this.groupModel.group?.displayName ?? '',
      id: this.groupModel.group?.id ?? '',
    }
    try {
      this.evaluateRespons(await this.updateUser(member))
      this.evaluateRespons(await this.userManagementService.getUser())

      this.toasterService.show({
        type: 'success',
        message: `user updated successfully`,
      })
    } catch (e: any) {
      return e
    }
  }

  private async updateUser(member: MemberModel): Promise<HttpResponse<string>> {
    const groupInfo = {
      displayName: this.groupModel.group?.displayName ?? '',
      id: this.groupModel.group?.id ?? '',
    }
    const scopes = member.backendScopes.filter((scope) =>
      this.groupModel.backendscopes.includes(scope),
    )
    member.backendScopes = scopes
    const updateUserResponse =
      await this.userManagementService.updateUserInGroup(member, groupInfo)

    return updateUserResponse
  }

  public async inviteUserWorkflow(
    mail: string,
    groupName: string,
  ): Promise<void> {
    const httpResponses: HttpResponse<any>[] = []
    try {
      this.evaluateRespons(await this.inviteUserToGroup(mail, groupName))
      this.evaluateRespons(await this.userManagementService.getUser())
      const userRole = this.groupModel.roles.find((x) => x.includes('user'))
      const member = this.userManagementService.groupModel
        .find((group) => group.group?.id === this.groupModel.group?.id)
        ?.members?.find((member) => member.mail === mail)
      if (member === undefined || userRole === undefined) return
      member.role = userRole
      this.evaluateRespons(await this.updateUser(member))
      this.evaluateRespons(await this.userManagementService.getUser())

      this.mailAddress = ''
      this.toasterService.show({
        type: 'success',
        message: `invite successfully`,
      })
    } catch (e: any) {
      this.mailAddress = ''
    }
  }

  public async deleteUserWorkflow(member: MemberModel): Promise<void> {
    try {
      this.evaluateRespons(await this.deleteUser(member))
      this.evaluateRespons(await this.userManagementService.getUser())

      this.mailAddress = ''
      this.toasterService.show({
        type: 'success',
        message: `user deleted successfully`,
      })
    } catch (e: any) {}
  }

  private async inviteUserToGroup(
    mail: string,
    groupName: string,
  ): Promise<HttpResponse<string>> {
    const inviteUserResponse =
      await this.userManagementService.inviteUserInGroup(mail, groupName)
    return inviteUserResponse
  }

  private async deleteUser(member: MemberModel): Promise<HttpResponse<string>> {
    const deleteUserResponse =
      await this.userManagementService.removeUserFromGroup(
        member.id,
        this.groupModel.group?.id ?? '',
        this.groupModel.group?.displayName ?? '',
      )
    return deleteUserResponse
  }

  private evaluateRespons(response: HttpResponse<any>): HttpResponse<any> {
    if (response.status !== 200) {
      this.toasterService.show({
        type: 'error',
        message: `${response.status}: ${response.statusText}`,
      })
      throw response
    }
    return response
  }
}
