import { Component, inject, OnDestroy, OnInit } from '@angular/core'
import { MsalBroadcastService, MsalService } from '@azure/msal-angular'
import {
  AuthenticationResult,
  EventMessage,
  EventType,
  IdTokenClaims,
  InteractionStatus,
} from '@azure/msal-browser'
import { filter, takeUntil } from 'rxjs/operators'
import { UserManagementService } from '../../service/user-management.service'
import { AuthService } from '../../service/auth.service'
import { AUTH_SERVICE } from '../app.config'
import { GroupModel, MemberModel } from '../../models/member-model'
import { Subject } from 'rxjs'

@Component({
  selector: 'app-home',
  template: `
    <div class="container">
      @if (loginDisplay) {
        <!--        <p>-->
        <!--          Request your profile information by clicking Profile above.-->
        <!--          {{ token }}-->
        <!--        </p>-->
        <div class="mt-4">
          @if (this.userManagementService.groupModel.length > 0) {
            @for (
              groupModel of this.userManagementService.groupModel;
              track groupModel.group?.id
            ) {
              <app-group-management [groupModel]="groupModel" />
              <br class="lh-lg" />
              <br class="lh-lg" />
            }
          } @else {
            <p>Loading...</p>
          }
        </div>
      } @else {
        <p class="my-4">Please sign-in to see group information.</p>
        <img src="assets/digital-collaboration.svg" />
      }
    </div>
  `,
  styleUrl: './home.component.scss',
})
export class HomeComponent implements OnInit, OnDestroy {
  _destroying$ = new Subject<void>()
  public loginDisplay = false

  constructor(
    private msalBroadcastService: MsalBroadcastService,
    private authTokenService: AuthService,
    public readonly userManagementService: UserManagementService,
  ) {}

  public ngOnInit(): void {
    console.log('enter ngOnInit in home component')
    this.msalBroadcastService.msalSubject$
      .pipe(
        filter(
          (msg: EventMessage) =>
            msg.eventType === EventType.LOGIN_SUCCESS ||
            msg.eventType === EventType.ACQUIRE_TOKEN_SUCCESS ||
            msg.eventType === EventType.SSO_SILENT_SUCCESS,
        ),
        takeUntil(this._destroying$),
      )
      .subscribe(async (result: EventMessage) => {
        console.log('subscribe to login')
        this.getUser()
      })

    this.msalBroadcastService.inProgress$
      .pipe(
        filter(
          (status: InteractionStatus) => status === InteractionStatus.None,
        ),
        takeUntil(this._destroying$),
      )
      .subscribe(() => {
        this.setLoginDisplay()
      })
  }

  ngOnDestroy(): void {
    this._destroying$.next(undefined)
    this._destroying$.complete()
  }

  public setLoginDisplay() {
    this.loginDisplay = this.authTokenService.loginDisplay
    const token = this.authTokenService.getAccessToken()
    console.log('enter setLoginDisplay')
    // if(this.loginDisplay){
    //   this.authTokenService.getAccessToken().then((response) => {
    //     this.token = response;
    //   });
    // }
  }

  public async getUser() {
    const token = await this.authTokenService.getAccessToken()
    this.userManagementService.setToken(token)
    const response = await this.userManagementService.getUser()
    console.log('getUser', response)
    if (response.status === 401) {
      // logout if token is expired
      this.authTokenService.logout()
    }
  }
}
