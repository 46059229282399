import { rbMultiSelectItem } from './group-model'

export interface IMemberModel {
  id: string
  mail: string
  displayName?: string
  givenName?: string
  surname?: string
  isAdmin?: boolean
  role: string
  backendScopes: string[]
  backendScopesExpiry?: BackendScopesExpiry | ''
  permissions: string
  dataFilters: string
}

export class MemberModel implements IMemberModel {
  id: string
  mail: string
  displayName?: string
  givenName?: string
  surname?: string
  isAdmin?: boolean
  role: string
  backendScopes: string[]
  backendScopesFrontEnd: { [key: string]: boolean }
  backendScopesExpiry?: BackendScopesExpiry
  backendScopesExpiryFrontEnd: { [key: string]: boolean }

  permissions: string
  dataFilters: string

  constructor(data: Partial<MemberModel>) {
    // Assign properties from the data object
    this.id = data.id || ''
    this.mail = data.mail || ''
    this.displayName = data.displayName
    this.givenName = data.givenName
    this.surname = data.surname
    this.isAdmin = data.isAdmin
    this.role = data.role || ''
    this.backendScopes = data.backendScopes || []
    this.permissions = data.permissions || ''
    this.dataFilters = data.dataFilters || ''
    this.backendScopesExpiry = data.backendScopesExpiry || {
      backendScopes: [],
      date: '',
    }

    this.backendScopesExpiry.backendScopes =
      this.backendScopesExpiry.backendScopes.map((scope) =>
        scope.replace(/^"|"$/g, ''),
      )

    // Process backendScopes to backendScopesFrontEnd
    this.backendScopesFrontEnd = {}
    this.backendScopesExpiryFrontEnd = {}

    if (this.backendScopes) {
      for (let scope of this.backendScopes) {
        this.backendScopesFrontEnd[scope] = true

        // Process backendScopeExpiry
        this.backendScopesExpiryFrontEnd[`"${scope}"`] =
          this.IsExpiryConfigured(scope)
      }
    }
  }

  private IsExpiryConfigured(scope: string): boolean {
    return (
      typeof this.backendScopesExpiry?.backendScopes.find(
        (x) => x === scope,
      ) === 'string'
    )
  }
}
export interface BackendScopesExpiry {
  backendScopes: string[]
  date: string
}
