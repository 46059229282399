import {
  Component,
  EventEmitter,
  Input,
  OnInit,
  output,
  Output,
} from '@angular/core'

@Component({
  selector: 'app-dropdown-single',
  templateUrl: './dropdown-single.component.html',
  styleUrl: './dropdown-single.component.scss',
  standalone: false,
})
export class DropdownSingleComponent implements OnInit {
  @Input() items: string[] = ['test', 'test2', 'test3']
  @Input() selected: string = '-'
  selectionChange = output<string>()

  public ngOnInit(): void {
    if (!this.selected || this.selected === '') {
      this.selected = '-'
    }
  }

  onItemSelect(item: string): void {
    if (this.selected !== item) {
      this.selected = item
      this.selectionChange.emit(this.selected)
    }
  }
}
