import { MemberModel } from './member-model'

export interface rbMultiSelectItem {
  id: string
  label: string
}

export interface Group {
  id: string
  displayName: string
}

export interface IGroupModel {
  roles: string[]
  backendscopes: string[]
  group?: Group
  dataFilters?: string[]
  permissions?: string[]
  members?: MemberModel[]
}

export class GroupModel implements IGroupModel {
  roles: string[]
  backendscopes: string[]
  backendScopesFrontEnd: rbMultiSelectItem[]
  group?: Group
  dataFilters?: string[]
  permissions?: string[]
  members?: MemberModel[]

  constructor(data: Partial<GroupModel>) {
    // Assign properties from the data object
    this.roles = data.roles || []
    this.backendscopes = data.backendscopes || []
    this.group = data.group
    this.dataFilters = data.dataFilters || []
    this.permissions = data.permissions || []
    this.members = data.members?.map((member) => new MemberModel(member)) || []

    // Process backendscopes to backendScopesFrontEnd
    if (this.backendscopes) {
      this.backendScopesFrontEnd = this.backendscopes.map((bs) => ({
        id: bs,
        label: bs,
      }))
    } else {
      this.backendScopesFrontEnd = []
    }
  }
}
