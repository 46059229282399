import { NgModule } from '@angular/core'
import { BrowserModule } from '@angular/platform-browser'

import { AppRoutingModule } from './app-routing.module'
import { AppComponent } from './app.component'

import {
  MSAL_GUARD_CONFIG,
  MSAL_INSTANCE,
  MSAL_INTERCEPTOR_CONFIG,
  MsalBroadcastService,
  MsalGuard,
  MsalInterceptor,
  MsalModule,
  MsalRedirectComponent,
  MsalService,
} from '@azure/msal-angular'
import { InteractionType, PublicClientApplication } from '@azure/msal-browser'

import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http'
import { HomeComponent } from './home/home.component'
import { APP_CONFIG, AUTH_SERVICE } from './app.config'
import { AuthService } from '../service/auth.service'
import { GroupManagementComponent } from './group-management/group-management.component'
import { FormsModule } from '@angular/forms'
import { ToastsContainer } from './controlls/toasts-container/toasts-container.component'
import {
  MSALGuardConfigFactory,
  MSALInstanceFactory,
  MSALInterceptorConfigFactory,
} from './core/utils/msal-utils'
import { ReplacePipe } from './pipes/replace.pipe'
import { RbUiComponentsModule } from '@inst-iot/bosch-angular-ui-components'
import { NgbToast } from '@ng-bootstrap/ng-bootstrap'
import { RbPopoverWithHoverDirective } from './directives/rb-popover-with-hover.directive'

@NgModule({
  declarations: [
    AppComponent,
    HomeComponent,
    GroupManagementComponent,
    ReplacePipe,
    ToastsContainer,
    RbPopoverWithHoverDirective,
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    // Import the HTTP client.
    HttpClientModule,
    FormsModule,
    RbUiComponentsModule,
    NgbToast,
  ],
  providers: [
    /* Changes start here. */
    MsalService,
    MsalBroadcastService,
    MsalGuard,
    {
      provide: MSAL_INSTANCE,
      useFactory: MSALInstanceFactory,
      deps: [APP_CONFIG],
    },
    {
      provide: MSAL_GUARD_CONFIG,
      useFactory: MSALGuardConfigFactory,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: MsalInterceptor,
      multi: true,
    },
    {
      provide: MSAL_INTERCEPTOR_CONFIG,
      useFactory: MSALInterceptorConfigFactory,
      deps: [APP_CONFIG],
    },
    {
      provide: AUTH_SERVICE,
      useClass: AuthService,
      multi: false,
    },

    /* Changes end here. */
  ],
  bootstrap: [AppComponent, MsalRedirectComponent],
})
export class AppModule {}
