<div class="d-flex bd-highlight mb-3">
  <h3 class="me-auto p-2 bd-highlight">
    {{ groupModel.group?.displayName | titlecase }}
  </h3>
  <div class="p-2 bd-highlight">
    <rb-form-input
      class="email-input"
      name="email"
      label="Add user to {{ groupModel.group?.displayName | titlecase }} group:"
      [(ngModel)]="mailAddress"
      required
      email
      autocomplete="email"
      data-cy="input-username"
      (keydown.enter)="
        inviteUserWorkflow(mailAddress, groupModel.group?.displayName ?? '')
      "
      placeholder="E-mail">
    </rb-form-input>
  </div>
  <div class="p-2 bd-highlight">
    <button
      class="rb-btn rb-primary rb-ic"
      (click)="
        inviteUserWorkflow(mailAddress, groupModel.group?.displayName ?? '')
      "
      data-cy="invite-user-btn"
      style="height: 48px; text-align: center">
      <i class="rb-ic rb-ic-user-add"></i>
    </button>
  </div>
</div>
<div class="row">
    <table
            class="m-table"
            *rbTable="'null'; fixedHeaderWidth: false; maxHeight: 'unset'">
        <thead>
        <tr>
            <th scope="col">Mail</th>
            <th scope="col">Name</th>
            <th scope="col">Role</th>
            <th scope="col">Scope</th>
            <th scope="col"></th>
            <th scope="col"></th>
        </tr>
        </thead>
        <tbody>
            @for (member of groupModel.members; track member.id) {
                <tr>
                    <td class="col-sm-3">{{ member.mail }}</td>
                    <td class="col-sm-2 align-content-center text-wrap">
                        <div class="d-flex justify-content-between ">
                            <div class="align-content-center">
                                @if (isRKeyPressed) {
                                    <span [rbTooltip]="tooltip" [noFix]="true">{{ member.displayName }}</span>
                                    <ng-template #tooltip>
                                        <div class="tooltip-template">
                                            <div class="text-decoration-underline">Permissions:</div>
                                            <div>
                                                {{ member.permissions | replace: ',':', ' }}
                                            </div>
                                            <br/>
                                            <div class="text-decoration-underline">DataFilters:</div>
                                            <div>
                                                {{ member.dataFilters | replace: ',':', ' }}
                                            </div>
                                        </div>
                                    </ng-template>
                                } @else {
                                    <span>{{ member.displayName }}</span>
                                }
                            </div>
                            @if (member.isAdmin) {
                                <div class="justify-content-center align-content-center ms-2">
                                    <i class="rb-ic rb-ic-user-mechanic" style="font-size: 200%;"></i>
                                </div>
                            }
                        </div>
                    </td>
                    <td class="col-sm-2 ">
                        <rb-form-custom-select name="role-selection-dropdown" label="Role" [(ngModel)]="member.role"
                                               (ngModelChange)="onRoleSelectionchange($event, member)"
                                               data-cy="role-selection-dropdown"
                                               style="width: 200px">
                            @for (role of groupModel.roles; track $index) {
                                <rb-form-select-option data-cy="dropdown-role-item" [value]="role">{{
                                        role
                                    }}
                                </rb-form-select-option>
                            }
                        </rb-form-custom-select>
                    </td>
                    <td class="col-sm-3 ">
                        <rb-form-multi-select name="scope-selection-dropdown" idField="id" labelField="label"
                                              [items]="groupModel.backendScopesFrontEnd"
                                              [ngModel]="member.backendScopesFrontEnd"
                                              label="Scopes" maxHeight="200px" class="m-0" style="width: 250px;"
                                              (ngModelChange)="onScopeSelectionchange($event, member)"
                                              [nothingSelectedLabel]="'-'"
                                              data-cy="scope-selection-dropdown">
                            <span *rbFormMultiSelectOption="let item"
                                  data-cy="dropdown-scope-item">{{ item.label }}</span>
                        </rb-form-multi-select>
                    </td>
                    <td class="col-sm-1 align-content-center">
                        <div class="d-flex justify-content-end">
                        <span class="rb-ic rb-ic-calendar-clock clickable" [class.activeColor]="isScopeExpirySet(member)"
                              [appPopoverWithHover]="ScopeExpiryDialog"
                              (popoverOpen)="onScopeExpirePopoverOpen($event, member)"
                              (popoverClosed)="onScopeExpirePopoverClose(member)"></span>
                            <ng-template let-close="close" let-fixed="fixed" #ScopeExpiryDialog>
                                <!-- <app-fixed-aware-content [shown]="shown"> -->
                                @if (fixed) {
                                    <div class="">
                                        <div class="d-flex justify-content-end">
                                            <button class="rb-btn rb-link" (click)="close()">
                                                <span class="rb-ic rb-ic-close rb-link"></span>
                                            </button>
                                        </div>
                                        <div class="mx-4 mt-3">

                                            <rb-form-date-input name="birthday" label="Expiry date"
                                                                [options]="{ dateFormat: 'd-m-Y', minDate: 'today' }"
                                                                [(ngModel)]="member.backendScopesExpiry!.date"
                                                                [showClearIcon]="true" required
                                                                class="flex-grow-1"></rb-form-date-input>
                                            <rb-form-multi-select name="scope-selection-dropdown"
                                                                  [items]="member.backendScopes"
                                                                  label="Scopes"
                                                                  maxHeight="200px" style="width: 250px;"
                                                                  [nothingSelectedLabel]="'-'"
                                                                  [(ngModel)]="member.backendScopesExpiryFrontEnd">
                                        <span *rbFormMultiSelectOption="let item">
                                            {{ item }}
                                        </span>
                                            </rb-form-multi-select>
                                            <div class="d-flex justify-content-end my-3">
                                                <button class="rb-btn mx-2"
                                                        (click)="clearExpiryAndClose(member, close)">
                                                    <span class="rb-ic rb-ic-broom-cloud-dirt"></span>
                                                    &nbsp;<span>Clear</span>
                                                </button>
                                                <button class="rb-btn rb-primary"
                                                        (click)="saveExpiryAndClose(member, close)">
                                                    <span class="rb-ic rb-ic-save "></span>
                                                    &nbsp;<span>Save</span>
                                                </button>
                                            </div>
                                        </div>
                                    </div>

                                } @else {
                                    <div class="m-2">
                                        @if (!isScopeExpirySet(member)) {
                                            No, scope expiry configured.
                                        } @else {

                                            <div class="mb-1">Expiry configured for
                                                <b>{{ getFormatedDate(member.backendScopesExpiry!.date) }}</b>:
                                            </div>
                                            @for (scope of member.backendScopesExpiry?.backendScopes; track $index) {
                                                <span>- {{ scope }}</span><br/>
                                            }
                                        }
                                    </div>
                                }

                                <!-- </app-fixed-aware-content> -->
                                <!-- } -->
                            </ng-template>

                        </div>
                    </td>
                    <td class="col-sm-1 align-content-center">
                        <div class="d-flex justify-content-end">
                            <button
                                    [disabled]="member.id == activeUserId()"
                                    class="btn rb-btn rb-secondary rb-ic btn-disable-cross-out"
                                    data-cy="delete-user-btn"
                                    (click)="openDialog(removeUserDialog, 'middle', member)">
                                <i class="bi bi-trash"></i>
                            </button>
                        </div>
                        <ng-template #removeUserDialog>
                            <rb-dialog
                                    dialogTitle="Remove User"
                                    headline="Remove user {{ member.displayName }}"
                                    confirmationButtonLabel="Confirm"
                                    cancelButtonLabel="Cancel"
                                    [alert]="{ type: 'error', errorCode: '' }"
                                    data-cy="delete-user-dialog">
                                <p>
                                    Are you sure you want to remove
                                    <b>{{ member.displayName }}</b> from the group?
                                </p>
                                <p>
                                    This action cannot be undone and
                                    {{ member.displayName }} will lose all access rights.
                                </p>
                                <p>Please confirm if you wish to proceed.</p>
                            </rb-dialog>
                        </ng-template>
                    </td>
                </tr>
            }
        </tbody>
    </table>
</div>
