import {
  ComponentRef,
  Directive,
  ElementRef,
  EventEmitter,
  HostListener,
  Input,
  OnDestroy,
  OnInit,
  Output,
  TemplateRef,
  ViewContainerRef,
} from '@angular/core'
import { PopoverComponent } from '@inst-iot/bosch-angular-ui-components'
import { Subscription } from 'rxjs'
import { attachPopoverToDocument } from '../../utils/position-utils'

@Directive({
  selector: '[appPopoverWithHover]',
  standalone: false,
})
export class RbPopoverWithHoverDirective implements OnDestroy {
  @Input() position: 'bottom' | 'top' | 'left' | 'right' = 'top'

  /**
   * Disables that the tooltip stays open when clicked.
   * Instead the tooltip is closed.
   */

  @Input() context!: Record<string, any>

  @Input() anchor!: ElementRef | HTMLElement

  @Output() popoverOpen = new EventEmitter<{ isFixed: boolean }>()
  @Output() popoverClosed = new EventEmitter()

  content!: TemplateRef<any> | string

  componentRef: ComponentRef<PopoverComponent> | null = null

  private closeSub: Subscription | null = null

  private fixed = false

  constructor(private viewContainerRef: ViewContainerRef) {}

  ngOnDestroy() {
    this.closePopover()
  }

  @Input('appPopoverWithHover') set template(tpl: TemplateRef<any> | string) {
    this.content = tpl
  }

  @HostListener('click')
  onClick() {
    if (!this.content) {
      return
    }
    this.fixed = !this.fixed
    this.updateComponentState()
    if (this.fixed) {
      this.popoverOpen.emit({ isFixed: this.fixed })
    }
  }

  @HostListener('mouseenter', ['$event'])
  onEnter(e: MouseEvent) {
    e.preventDefault()
    if (!this.content) {
      return
    }

    this.openPopover()
  }

  @HostListener('mouseleave', ['$event'])
  onLeave(e: MouseEvent) {
    if (!this.fixed) {
      e.preventDefault()
      this.closePopover()
    }
  }

  openPopover() {
    if (this.componentRef) {
      this.updateComponentState()
      return
    }

    this.componentRef = this.viewContainerRef.createComponent(PopoverComponent)
    const instance = this.componentRef.instance
    instance.anchor = this.anchor || this.viewContainerRef.element
    instance.content = this.content
    instance.primaryPos = this.position
    instance.closableByOutsideClick = false
    if (this.context) {
      Object.assign(instance.context, this.context)
    }
    instance.context.fixed = this.fixed
    this.updateComponentState()
    this.closeSub = instance.close.subscribe((x) => {
      this.closePopover()
    })
    this.viewContainerRef.element.nativeElement.classList.add('open')
    attachPopoverToDocument(this.componentRef.location.nativeElement)
    this.popoverOpen.emit({ isFixed: this.fixed })
  }

  updateComponentState() {
    if (!this.componentRef) return
    const instance = this.componentRef!.instance
    instance.show()
    instance.context.fixed = this.fixed
    if (this.fixed) {
      this.viewContainerRef.element.nativeElement.classList.add('open-fixed')
      instance.popoverElementRef.nativeElement.classList.add('open-fixed')
    }
  }

  closePopover() {
    this.fixed = false
    if (this.componentRef) {
      if (this.closeSub) {
        this.closeSub.unsubscribe()
      }
      this.viewContainerRef.element.nativeElement.classList.remove('open')
      this.viewContainerRef.element.nativeElement.classList.remove('open-fixed')
      const viewIndex = this.viewContainerRef.indexOf(
        this.componentRef.hostView,
      )
      if (viewIndex !== -1) {
        this.viewContainerRef.remove(viewIndex)
      }
      this.componentRef = null
    }
    this.popoverClosed.emit()
  }
}
