import {
  Component,
  computed,
  HostListener,
  Input,
  Signal,
  OnInit,
  TemplateRef,
} from '@angular/core'
import { IMemberModel, MemberModel } from '../../models/member-model'
import { UserManagementService } from '../../service/user-management.service'
import { ToastService } from '../../service/app-toast.service'
import { HttpErrorResponse, HttpResponse } from '@angular/common/http'
import { concat, concatMap, Observable, Subscription, throwError } from 'rxjs'
import { AuthService } from '../../service/auth.service'
import { GroupModel } from '../../models/group-model'
import {
  DialogCloseEventWithResult,
  ModalService,
} from '@inst-iot/bosch-angular-ui-components'

@Component({
  selector: 'app-group-management',
  templateUrl: './group-management.component.html',
  styleUrl: './group-management.component.scss',
  standalone: false,
})
export class GroupManagementComponent implements OnInit {
  @Input() public groupModel!: GroupModel
  public mailAddress: string = ''
  public isRKeyPressed: boolean = false

  activeUserId: Signal<string> = computed(() => this.authService.activeUserId())
  private updateUserSubscription: Subscription | null = null

  @HostListener('window:keydown.r', ['$event']) onKeyDown(
    event: KeyboardEvent,
  ) {
    // Beispiel: Reagiere auf spezifische Tasten
    if (!this.isRKeyPressed) {
      this.isRKeyPressed = true
    }
  }

  @HostListener('window:keyup.r', ['$event']) onKeyUp(event: KeyboardEvent) {
    // Beispiel: Reagiere auf spezifische Tasten
    this.isRKeyPressed = false
  }

  constructor(
    private readonly userManagementService: UserManagementService,
    private readonly authService: AuthService,
    private readonly toasterService: ToastService,
    private modalService: ModalService,
  ) {}

  ngOnInit(): void {}

  public async onRoleSelectionchange(
    selectedRole: string,
    member: MemberModel,
  ): Promise<void> {
    //console.log(`${member.displayName} new role: ${selectedRole}`)

    await this.updateUserWorkflow(this.excludeBackendScopesExpiry(member))
  }

  public async onScopeSelectionchange(
    selectedScopes: any[],
    member: MemberModel,
  ) {
    const newSelectedScopes = Object.entries(selectedScopes)
      .filter(([key, value]) => value === true) // Filter out entries with true values
      .map(([key, _]) => key)
    //console.log(`${member.displayName} new scopes: ${newSelectedScopes}`)
    member.backendScopes = newSelectedScopes

    await this.updateUserWorkflow(
      this.updateOrExcludeBackendScopesExpiry(member),
    )
  }

  private updateOrExcludeBackendScopesExpiry(
    member: IMemberModel,
  ): IMemberModel {
    // exlude backendScopesExpiry from member
    const temp = member.backendScopes
    if (
      member.backendScopesExpiry === undefined ||
      member.backendScopesExpiry === ''
    )
      return { ...member, backendScopesExpiry: undefined }

    if (
      member.backendScopesExpiry.backendScopes.some(
        (scope) => !member.backendScopes.includes(scope),
      )
    ) {
      // remove all scopes that are no longer granted to this member
      member.backendScopesExpiry.backendScopes =
        member.backendScopesExpiry.backendScopes.filter((scope) =>
          member.backendScopes.includes(scope),
        )

      // if there is no longer a scope configured but still a date set
      // delete the hole configuration
      if (
        member.backendScopesExpiry.backendScopes.length === 0 &&
        member.backendScopesExpiry.date !== ''
      ) {
        member.backendScopesExpiry = ''
      }
    }
    return member
  }

  private excludeBackendScopesExpiry(member: IMemberModel): IMemberModel {
    // exlude backendScopesExpiry from member
    return { ...member, backendScopesExpiry: undefined }
  }

  private async updateUserWorkflow(member: IMemberModel) {
    const updateUser$ = this.getUpdateUserObservable(member)
    const getUser$ = this.userManagementService.getUser()

    if (this.updateUserSubscription) {
      this.updateUserSubscription.unsubscribe()
      this.updateUserSubscription = null
    }

    const subscription = concat(updateUser$, getUser$).subscribe({
      next: (response) => {},
      complete: () => {
        this.toasterService.show({
          type: 'success',
          message: `user updated successfully`,
        })
        this.updateUserSubscription = null
      },
      error: (errorResp: HttpErrorResponse | Error) => {
        if (errorResp instanceof HttpErrorResponse) {
          this.toasterService.show({
            type: 'error',
            message: `${errorResp.status}: ${errorResp.error}`,
          })
        } else {
          this.toasterService.show({
            type: 'error',
            message: `Error: ${errorResp.message}`,
          })
        }
        this.updateUserSubscription = null
      },
    })
    this.updateUserSubscription = subscription
  }

  private getUpdateUserObservable(member: IMemberModel): Observable<string> {
    const groupInfo = {
      displayName: this.groupModel.group?.displayName ?? '',
      id: this.groupModel.group?.id ?? '',
    }
    const scopes = member.backendScopes.filter((scope) =>
      this.groupModel.backendscopes.includes(scope),
    )
    member.backendScopes = scopes

    const updateUser$ = this.userManagementService.updateUserInGroup(
      member,
      groupInfo,
    )

    return updateUser$
  }

  public async inviteUserWorkflow(
    mail: string,
    groupName: string,
  ): Promise<void> {
    mail = mail.trim()
    if (mail == '') {
      return
    }
    const inviteUser$ = this.userManagementService.inviteUserInGroup(
      mail,
      groupName,
    )
    const getUser$ = this.userManagementService.getUser()

    inviteUser$
      .pipe(
        concatMap(() => getUser$),
        concatMap(() => {
          const userRole = this.groupModel.roles.find((x) => x.includes('user'))
          const member = this.userManagementService.groupModel
            .find((group) => group.group?.id === this.groupModel.group?.id)
            ?.members?.find((member) => member.mail === mail)

          if (member === undefined || userRole === undefined) {
            return throwError(() => new Error('Member or userRole not found.'))
          }

          member.role = userRole
          return this.getUpdateUserObservable(
            this.excludeBackendScopesExpiry(member),
          ) // Rückgabe von `updateUser$`
        }),
        concatMap(() => getUser$),
      )
      .subscribe({
        complete: () => {
          this.mailAddress = ''

          this.toasterService.show({
            type: 'success',
            message: `User successfully added`,
          })
        },
        error: (errorResp: HttpErrorResponse | Error) => {
          if (errorResp instanceof HttpErrorResponse) {
            if (errorResp.error === 'User not found') {
              this.toasterService.show({
                type: 'error',
                message: 'User not registered',
              })
            } else {
              this.toasterService.show({
                type: 'error',
                message: `${errorResp.status}: ${errorResp.error}`,
              })
            }
          } else {
            this.toasterService.show({
              type: 'error',
              message: `Error: ${errorResp.message}`,
            })
          }
        },
      })
  }

  async openDialog(
    tpl: TemplateRef<any>,
    position: 'top' | 'middle' | 'bottom',
    member: MemberModel,
  ) {
    const closeEventResult =
      await this.modalService.open<DialogCloseEventWithResult>(tpl, {
        maxWidth: '650px',
        position,
      })

    switch (closeEventResult.event) {
      case 'confirmed':
        this.removeUserWorkflow(member)
        break
      default:
        break
    }
  }

  public removeUserWorkflow(member: MemberModel): void {
    const removeUser$ = this.userManagementService.removeUserFromGroup(
      member.id,
      this.groupModel.group?.id ?? '',
      this.groupModel.group?.displayName ?? '',
    )
    const getUser$ = this.userManagementService.getUser()

    concat(removeUser$, getUser$).subscribe({
      next: (response) => {},
      complete: () => {
        this.mailAddress = ''
        this.toasterService.show({
          type: 'success',
          message: `user successfully removed`,
        })
      },
      error: (errorResp: HttpErrorResponse | Error) => {
        if (errorResp instanceof HttpErrorResponse) {
          if (errorResp.status === 401) {
            this.toasterService.show({
              type: 'error',
              message: `Error: You are not allowed to remove this user.`,
            })
          } else {
            this.toasterService.show({
              type: 'error',
              message: `${errorResp.status}: ${errorResp.error}`,
            })
          }
        } else {
          this.toasterService.show({
            type: 'error',
            message: `Error: ${errorResp.message}`,
          })
        }
      },
    })
  }

  public async onScopeExpirePopoverOpen(
    event: { isFixed: boolean },
    member: MemberModel,
  ): Promise<void> {}

  public onScopeExpirePopoverClose(member: MemberModel) {}

  public clearExpiryAndClose(member: MemberModel, close: () => void) {
    console.log(`clearExpiryAndClose for: ${member.displayName} `)
    member.backendScopesExpiry = {
      backendScopes: [],
      date: '',
    }
    const newObj: { [key: string]: boolean } = {}
    Object.keys(member.backendScopesExpiryFrontEnd).forEach(
      (key) => (newObj[key] = false),
    )
    member.backendScopesExpiryFrontEnd = newObj
  }

  private isExpiryInputValid(member: MemberModel): boolean {
    const areScopesSet = Object.values(member.backendScopesExpiryFrontEnd).some(
      (v) => v,
    )
    const isDateSet = (member.backendScopesExpiry?.date.length ?? 0) > 0

    if ((areScopesSet && isDateSet) || (!areScopesSet && !isDateSet)) {
      return true
    }
    if (!areScopesSet) {
      this.toasterService.show({
        type: 'error',
        message: `Invalid: A scope has to be configured.`,
      })
    }
    if (!isDateSet) {
      this.toasterService.show({
        type: 'error',
        message: `Invalid: A date has to be configured.`,
      })
    }
    return false
  }

  public async saveExpiryAndClose(member: MemberModel, close: () => void) {
    if (!this.isExpiryInputValid(member)) return
    close()

    member.backendScopesExpiry!.backendScopes = Object.keys(
      member.backendScopesExpiryFrontEnd,
    )
      .filter((key) => member.backendScopesExpiryFrontEnd[key])
      .map((scope) => scope.replace(/^"|"$/g, ''))

    const date = new Date(member.backendScopesExpiry!.date)
    date.setMinutes(-date.getTimezoneOffset())
    const day = date.getDate().toString().padStart(2, '0')
    const month = (date.getMonth() + 1).toString().padStart(2, '0')
    const year = date.getFullYear()
    member.backendScopesExpiry!.date = `${year}-${month}-${day}T00:00:00.000Z`

    const cleanBackendScopesExiry =
      (member.backendScopesExpiry?.backendScopes.length ?? 0 > 0)
        ? member.backendScopesExpiry
        : ''
    const memberCopy = {
      ...member,
      backendScopesExpiry: cleanBackendScopesExiry,
    } as IMemberModel

    await this.updateUserWorkflow(memberCopy)
  }

  public isScopeExpirySet(member: MemberModel): boolean {
    if (member.backendScopesExpiry === undefined) {
      return false
    }
    const hasTrueValue = Object.values(member.backendScopesExpiryFrontEnd).some(
      (v) => v === true,
    )
    const hasBackendScopes =
      (member.backendScopesExpiry?.backendScopes.length ?? 0) > 0
    return member.backendScopesExpiry.date !== '' && hasBackendScopes
  }

  public getFormatedDate(dateString: string): string {
    const date = new Date(dateString)
    date.setMinutes(-date.getTimezoneOffset())
    const day = date.getDate().toString().padStart(2, '0')
    const month = (date.getMonth() + 1).toString().padStart(2, '0')
    const year = date.getFullYear()

    return `${day}.${month}.${year}`
  }
}
