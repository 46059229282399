import {
  Component,
  EventEmitter,
  Input,
  OnInit,
  output,
  Output,
} from '@angular/core'

@Component({
  selector: 'app-dropdown-single',
  template: `
    <div class="dropdown">
      <button
        class="btn btn-secondary dropdown-toggle"
        type="button"
        data-bs-toggle="dropdown"
        aria-expanded="false">
        {{ selected }}
      </button>
      <ul class="dropdown-menu">
        @for (item of items; track item) {
          <li>
            <a
              [class.active]="item === selected"
              class="dropdown-item"
              (click)="onItemSelect(item)"
              href="#">
              {{ item }}
            </a>
          </li>
        }
      </ul>
    </div>
  `,
  styleUrl: './dropdown-single.component.scss',
})
export class DropdownSingleComponent implements OnInit {
  @Input() items: string[] = ['test', 'test2', 'test3']
  @Input() selected: string = '-'
  selectionChange = output<string>()

  public ngOnInit(): void {
    if (this.selected === '' || this.items.indexOf(this.selected) === -1) {
      this.selected = '-'
    }
  }

  onItemSelect(item: string): void {
    if (this.selected !== item) {
      this.selected = item
      this.selectionChange.emit(this.selected)
    }
  }
}
