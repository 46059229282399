<div class="row">
    <h3 class="col">
        {{ groupModel.group?.displayName | titlecase }}
    </h3>
    <div class="col d-flex px-0">
        <div class="input-group flex-grow-1">
            <span class="input-group-text" id="basic-addon1">
                <i class="bi bi-person-fill-add h5 m-auto"></i>
            </span>
            <input type="text" class="form-control" placeholder="Mail address" aria-label="Username"
                   aria-describedby="basic-addon1" data-cy="input-username" [(ngModel)]="mailAddress"
                   (keydown.enter)="inviteUserWorkflow(mailAddress, groupModel.group?.displayName ?? '')"
            />
        </div>
        <button class="btn btn-outline btn-outline-success ms-2" (click)="
          inviteUserWorkflow(mailAddress, groupModel.group?.displayName ?? '')
        " data-cy="invite-user-btn">
            <i class="bi bi-send-fill h5 "></i>
        </button>
    </div>
</div>
<div class="row">
    <table class="table table-striped">
        <thead>
        <tr>
            <th scope="col">Mail</th>
            <th scope="col">Name</th>
            <th scope="col">Role</th>
            <th scope="col">Scope</th>
            <th scope="col"></th>
        </tr>
        </thead>
        <tbody>
            @for (member of groupModel.members; track member.id) {
                <tr>
                    <td class="col-sm-3">{{ member.mail }}</td>
                    <td class="col-sm-2 align-content-start text-wrap">
                        <div class="d-flex justify-content-between">
                            <div>

                                @if (isRKeyPressed) {
                                    <app-tooltip>
                                        <ng-container slot="target">
                                            {{ member.displayName }}
                                        </ng-container>
                                        <ng-container slot="tooltip">
                                            <div class="text-decoration-underline">Permissions:</div>
                                            <div>
                                                {{ member.permissions | replace: ',':', ' }}
                                            </div>
                                            <br/>
                                            <div class="text-decoration-underline">DataFilters:</div>
                                            <div>
                                                {{ member.dataFilters | replace: ',':', ' }}
                                            </div>
                                        </ng-container>
                                    </app-tooltip>
                                } @else {
                                    {{ member.displayName }}
                                }
                            </div>
                            @if (member.isAdmin) {
                                <div class="justify-content-center align-content-center ms-2">
                                    <i class="bi bi-gem"></i>
                                </div>
                            }
                        </div>
                    </td>
                    <td class="col-sm-2">
                        <app-dropdown-single [selected]="member.role" [items]="groupModel.roles"
                                             (selectionChange)="onRoleSelectionchange($event, member)"
                                             data-cy="role-selection-dropdown"/>
                    </td>
                    <td class="col-sm-3">
                        <app-dropdown-multi [selected]="member.backendScopes" [items]="groupModel.backendscopes"
                                            (selectionChange)="onScopeSelectionchange($event, member)"
                                            data-cy="scope-selection-dropdown"/>
                    </td>
                    <td class="col-sm-1">
                        <div class="d-flex justify-content-end">
                            <button [disabled]="member.id == activeUserId() "
                                    class="btn btn-outline btn-outline-danger ms-2 hover-button"
                                    data-cy="delete-user-btn"
                                    (click)="deleteUserWorkflow(member)">
                                <i class="bi bi-trash"></i>
                            </button>
                        </div>
                    </td>
                </tr>
            }
        </tbody>
    </table>
</div>