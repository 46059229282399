import { Inject, Injectable } from '@angular/core'
import {
  HttpClient,
  HttpErrorResponse,
  HttpHeaders,
  HttpParams,
  HttpResponse,
} from '@angular/common/http'
import { IMemberModel, MemberModel } from '../models/member-model'
import {
  catchError,
  firstValueFrom,
  lastValueFrom,
  map,
  Observable,
  tap,
  throwError,
} from 'rxjs'
import { APP_CONFIG } from '../app/app.config'
import { AppConfigModel } from '../models/app-config.model'
import { GroupModel, IGroupModel } from '../models/group-model'

@Injectable({
  providedIn: 'root',
})
export class UserManagementService {
  public groupModel: GroupModel[] = []

  constructor(
    private readonly http: HttpClient,
    @Inject(APP_CONFIG) private readonly config: AppConfigModel,
  ) {
    this.config.backend_url
  }

  public getUser(): Observable<GroupModel[]> {
    const httpOptions = {}

    const observable$ = this.http
      .get<
        IGroupModel[]
      >(`${this.config.backend_url}/api/identity/users`, httpOptions)
      .pipe(
        catchError((error: HttpErrorResponse) => {
          // Capture the error and re-throw with status and message
          return throwError(() => error)
        }),
        map((groupModels) => groupModels.map((gm) => new GroupModel(gm))),
        tap({
          next: (groupModels) => {
            this.groupModel.length = 0
            this.groupModel.push(...groupModels)
          },
        }),
      )
    return observable$
  }

  public inviteUserInGroup(
    mail: string,
    groupName: string,
  ): Observable<string> {
    const httpOptions = {
      responseType: 'text' as 'json',
    }
    const requestBody = {
      mail: mail,
    }
    const inviteUser$ = this.http
      .put<string>(
        `${this.config.backend_url}/api/groups/${groupName}/users`,
        requestBody,
        httpOptions,
      )
      .pipe(
        catchError((error: HttpErrorResponse) => {
          // Capture the error and re-throw with status and message
          return throwError(() => error)
        }),
      )
    return inviteUser$
  }

  public updateUserInGroup(
    memberModel: IMemberModel,
    groupInfo: { displayName: string; id: string },
  ): Observable<string> {
    const httpOptions = {
      responseType: 'text' as 'json',
    }

    const requestBody = {
      id: memberModel.id,
      role: memberModel.role,
      backendScopes: memberModel.backendScopes,
      group: groupInfo,
      ...(memberModel.backendScopesExpiry !== undefined
        ? { backendScopesExpiry: memberModel.backendScopesExpiry }
        : {}),
    }

    const updateUser$ = this.http
      .put<string>(
        `${this.config.backend_url}/api/users/${memberModel.id}`,
        requestBody,
        httpOptions,
      )
      .pipe(
        catchError((error: HttpErrorResponse) => {
          // Capture the error and re-throw with status and message
          return throwError(() => error)
        }),
      )
    return updateUser$
  }

  public removeUserFromGroup(
    userId: string,
    groupId: string,
    groupDisplayName: string,
  ): Observable<string> {
    const httpOptions = {
      responseType: 'text' as 'json',
      params: new HttpParams().set('groupid', groupId), // Params hinzugefügt
    }
    const removeUser$ = this.http
      .delete<string>(
        `${this.config.backend_url}/api/groups/${groupDisplayName}/users/${userId}`,
        httpOptions,
      )
      .pipe(
        catchError((error: HttpErrorResponse) => {
          // Capture the error and re-throw with status and message
          return throwError(() => error)
        }),
      )
    return removeUser$
  }
}
