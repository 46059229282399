import { Component, Inject, Signal, computed } from '@angular/core'
import { HttpClient } from '@angular/common/http'
import { MSAL_GUARD_CONFIG, MsalGuardConfiguration } from '@azure/msal-angular'
import { APP_CONFIG } from './app.config'
import { AppConfigModel } from '../models/app-config.model'
import { AuthService } from '../service/auth.service'

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrl: './app.component.scss',
  standalone: false,
})
export class AppComponent {
  title = 'User Management'

  loginDisplay = computed(() => this.authService.loginDisplay())
  displayName: Signal<string> = computed(() => this.authService.displayName())

  constructor(
    @Inject(MSAL_GUARD_CONFIG) private msalGuardConfig: MsalGuardConfiguration,
    @Inject(APP_CONFIG) private readonly config: AppConfigModel,
    private authService: AuthService,
    private readonly http: HttpClient,
  ) {}

  login() {
    this.authService.login()
  }

  logout() {
    this.authService.logout()
  }
}
