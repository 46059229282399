import {
  LogLevel,
  Configuration,
  BrowserCacheLocation,
} from '@azure/msal-browser'

const isIE =
  window.navigator.userAgent.indexOf('MSIE ') > -1 ||
  window.navigator.userAgent.indexOf('Trident/') > -1

const authorityBase = 'boschidasb2cdev.b2clogin.com'
const tenant = 'boschidasb2cdev.onmicrosoft.com'
const aadb2c_policy_signin = 'B2C_1_SIGNIN_SIGNUP_MAPS_D'
const aadb2c_policy_edit = 'B2C_1_EDIT'

export const b2cPolicies = {
  names: {
    signUpSignIn: 'B2C_1_SIGNIN_SIGNUP_MAPS_D',
    editProfile: 'B2C_1_EDIT',
  },
  authorities: {
    signUpSignIn: {
      authority: `https://${authorityBase}/${tenant}/${aadb2c_policy_signin}`,
    },
    editProfile: {
      authority: `https://${authorityBase}/${tenant}/${aadb2c_policy_edit}`,
    },
  },
  authorityDomain: 'boschidasb2cdev.b2clogin.com',
}

export const msalConfig: Configuration = {
  auth: {
    clientId: '02a93322-5c49-4dec-9680-6ee4c6da5ec4', //'e78fc5c1-a8aa-4a72-a3ae-e70feac8ca34',
    authority: b2cPolicies.authorities.signUpSignIn.authority,
    knownAuthorities: [b2cPolicies.authorityDomain],
    redirectUri: '/',
  },
  cache: {
    cacheLocation: BrowserCacheLocation.LocalStorage,
    storeAuthStateInCookie: isIE,
  },
  system: {
    loggerOptions: {
      loggerCallback: (logLevel, message, containsPii) => {
        console.log(message)
      },
      logLevel: LogLevel.Verbose,
      piiLoggingEnabled: false,
    },
  },
}

// export const protectedResources = {
//   todoListApi: {
//     endpoint: 'http://localhost:4200',
//     scopes: [],
//   },
// };
// export const loginRequest = {
//   scopes: [],
// };
